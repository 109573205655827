import * as React from "react"

import Footer from "../components/layout/footer"
import Header from "../components/layout/header"

const CookiesPage = () => {
  return (
    <div className="min-h-screen bg-primary-900 text-white">
      <div className="container mx-auto px-2">
        <Header />

        <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-xl mx-auto py-16 sm:py-24">
            <div className="text-center">
              <h1 className="mt-2 text-4xl font-semibold tracking-tight sm:text-5xl sm:tracking-tight">
                Coming soon
              </h1>

              <p className="mt-2 text-lg text-primary-200">We're working hard on this page... Come back soon.</p>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </div>
  )
}

export const Head = () => <>
  <title>Coming soon - Boundary CMS</title>

  <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
  <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
  <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
  <link rel="manifest" href="/site.webmanifest" />
  <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#515BE7" />
  <meta name="msapplication-TileColor" content="#515BE7" />
  <meta name="theme-color" content="#ffffff" />
</>

export default CookiesPage
